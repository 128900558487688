import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Check, Warning, Send, Info, ErrorOutline } from "@material-ui/icons";
import { getRoutePathname } from "../../../Config/Route";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, makeStyles } from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import { TableReload } from "../../../Action/TableAction";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import { inArray } from "../../../Tool/ArrayCommon";
import { green } from '@material-ui/core/colors';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';

function LinearProgressWithLabel(props) {

    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        }
        else if (value >= 33 && value < 66) {
            return '#db770a';
        }
        else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function PersonalRelaunchList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionPersonalPaymentType, setOptionPersonalPaymentType] = React.useState([]);
    const [isTall, setIsTall] = React.useState(true);
    const [show, setShow] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [optionStates, setOptionStates] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Facturé' },
        { value: 2, label: 'Non Facturé' },
        { value: 3, label: 'En litige' }
    ]);
    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "organismName",
            label: "Organisme",
            options: { filter: true, sort: true }
        },
        {
            name: "username",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "groupFormationName",
            label: "Groupe de formation",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Intitulé de formation",
            options: { filter: false, sort: true }
        },
        {
            name: "completion",
            label: "Complétion",
            options: { filter: false, sort: true }
        },
        {
            name: "availableAt",
            label: "Date de début",
            options: { filter: true, sort: true }
        },
        {
            name: "availableEndAt",
            label: "Date de fin",
            options: { filter: true, sort: true }
        },
        {
            name: "deletedAt",
            label: "Date de suppression",
            options: { filter: true, sort: true }
        },
        {
            name: "cancelAt",
            label: "Date de desinscription",
            options: { filter: true, sort: true }
        },
        {
            name: "lastRelaunchAt",
            label: "Relance",
            options: { filter: true, sort: true }
        },
        {
            name: "personalPaymentTypeName",
            label: "Type de paiement",
            options: { filter: true, sort: true }
        },
        {
            name: "litigeStripeSpan",
            label: "Litige stripe",
            options: { filter: true, sort: true }
        },
        {
            name: "billingPrice",
            label: "Prix",
            options: { filter: true, sort: true }
        },
        {
            name: "personalPaymentAt",
            label: "Date de paiement",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser la fiche client'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', { id: row.prospect }))}>
                        <Assignment />
                    </IconButton>
                </Tooltip>

                {!row.availableAt && !row.personalPaymentAt && !row.billingPrice &&
                    <Tooltip title={'Relance de paiement'} placement="left">
                        <IconButton onClick={() => {
                            setShow(true);
                            setRow(row);
                            handler.setValue('relaunchAt', moment());
                        }}>
                            <Send />
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].organism ? cacheTableServer['crm_personal_relaunch_registration_list'].organism : 'all',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].formationGroup ? cacheTableServer['crm_personal_relaunch_registration_list'].formationGroup : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].year ? cacheTableServer['crm_personal_relaunch_registration_list'].year : currentYear,
            options: {}
        },
        personalPaymentType: {
            name: 'personalPaymentType',
            label: 'Type de paiement',
            textHelper: 'Trier par type de paiement.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].personalPaymentType ? cacheTableServer['crm_personal_relaunch_registration_list'].personalPaymentType : 'all',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].state ? cacheTableServer['crm_personal_relaunch_registration_list'].personalPaymentType : 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);

    const [form, setForm] = React.useState({
        relaunchAt: {
            name: 'relaunchAt',
            label: 'Date de la relance',
            textHelper: 'Choisissez une date de la relance.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Ajouter un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['crm_personal_relaunch_registration_list'] = 0;
        cacheTableServer['crm_personal_relaunch_registration_list'].page = 0;
        cacheTableServer['crm_personal_relaunch_registration_list'][index] = value;
        dispatch(TableReload('crm_personal_relaunch_registration_list'))
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Suivi des inscriptions',
            context: 'CRM',
            description: 'Liste des inscriptions en financement personnel.'
        });

        // Year
        let yearsTMP = [];
        for (let i = currentYear + 1; i > currentYear - 2; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });

        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormationGroup(data);
                }
            });

        Api.get({
            route: 'select_personal_payment_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionPersonalPaymentType(data);
                }
            });

        setReadyContent(true);
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            let data = handler.getData();
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            Api.post({
                route: 'crm_personal_relaunch_registration_mail',
                data: { relaunchAt: data.relaunchAt, comment: data.comment },
                params: { registration: row.id }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('crm_personal_relaunch_registration_list'));
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        setShow(false);
                        setRow(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Relance envoyée.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={2} lg={2}>
                                <SelectComponent name={'personalPaymentType'} handler={handlerSearch} options={optionPersonalPaymentType} onChange={(val) => inputSearchChange('personalPaymentType', val)} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                <SelectComponent name={'state'} handler={handlerSearch} options={optionStates} onChange={(val) => inputSearchChange('state', val)} />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br />

            <TableComponent
                id={'crm_personal_relaunch_registration_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Inscriptions'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();

                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        organism: (datas.organism === 'all') ? '' : datas.organism,
                        formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                        personalPaymentType: (datas.personalPaymentType === 'all') ? '' : datas.personalPaymentType,
                        state: (datas.state === 'all') ? '' : datas.state,
                    };

                    Api.get({
                        route: 'crm_personal_relaunch_registration_list',
                        data: filters
                    },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].availableAt = data[index].availableAt ? moment(data[index].availableAt.date).format('ll') : '-';
                                data[index].availableEndAt = data[index].availableEndAt ? moment(data[index].availableEndAt.date).format('ll') : '-';
                                data[index].deletedAt = data[index].deletedAt ? moment(data[index].deletedAt.date).format('lll') : '-';
                                data[index].cancelAt = data[index].cancelAt ? moment(data[index].cancelAt.date).format('lll') : '-';
                                data[index].personalPaymentAt = data[index].personalPaymentAt ? moment(data[index].personalPaymentAt.date).format('lll') : '-';
                                data[index].billingPrice = data[index].billingPrice ? data[index].billingPrice + '€' : '-';
                                data[index].formationName = <span style={{ width: 270, display: 'block' }}>{data[index].formationName}</span>;
                                data[index].litigeStripeSpan = data[index].inStripeLitige ? <Tooltip title={'Attention litige paiement'}><Warning value='true' color='error' /></Tooltip> : <Tooltip title={'Aucun litige'}><Check className={classes.checkIcon} value='false' color='primary' /></Tooltip>;
                                data[index].completion = <div style={{ width: 150 }}><LinearProgressWithLabel value={data[index].completion} /></div>;
                                data[index].lastRelaunchAt = data[index].relaunchs.length > 0 ? <div style={{ width: 140 }}>
                                    <Tooltip title={
                                        <span>{data[index].relaunchs.map((relaunch, i) =>
                                            <div key={i}>
                                                <p style={{ marginBottom: 0 }}>{relaunch.by} : Relance {relaunch.type} le {moment(relaunch.date).format('lll')}</p>
                                                {relaunch.comment && <p style={{ margin: 0, marginLeft: 10 }}>*Commentaire : {relaunch.comment}</p>}
                                            </div>)}
                                        </span>} placement="left">
                                        <Info style={{ display: 'inline-block', verticalAlign: 'bottom', margin: '0 5px', color: '#2190ff' }} />
                                    </Tooltip>
                                    <span>{data[index].lastRelaunchAt ? moment(data[index].lastRelaunchAt).format('lll') : ''}</span>
                                </div> : '-';
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br />

            <Dialog open={show}>
                <DialogTitle style={{ fontSize: 15 }}>Envoyer une relance de paiement <br />Inscription - {row ? row.id : ''}</DialogTitle>
                <DialogContent>
                    <div>
                        {(row && row.lastRelaunchAt != "-") &&
                            <p style={{margin: 0, paddingBottom: 20, fontSize: 13, textAlign: 'center', color: '#f44336'}}><ErrorOutline style={{marginRight: 5, verticalAlign: 'sub'}} color='error'/>La dernière relance a été effectuée le : {moment(row.lastRelaunchAt).format('ll')}</p>
                        }
                        <DatePickerComponent name={'relaunchAt'} handler={handler} />
                        <TextFieldComponent name={'comment'} handler={handler} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => { setShow(false); setRow(null); }} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Envoyer'} />
                </DialogActions>
            </Dialog>

            <br />

        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    checkIcon: {
        '&.MuiSvgIcon-colorPrimary': {
            color: green[600]
        }
    },
});

export default PersonalRelaunchList;
