import React from 'react';
import {LinearProgress as LinearProgressMUI, makeStyles} from "@material-ui/core";
import {theme} from "../../../../App";
import {useSelector} from "react-redux";

function CardFormation(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [sizeView, setSizeView] = React.useState('');
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        setSizeView(screenReducer.screen);
    }, [screenReducer.screen]);

    return (
        <div className={classes.box} style={{height: sizeView === 'XS' ? 180 : 340}} onClick={() => props.onClick()}>

            {
                sizeView !== 'XS' &&
                <>
                    {!load && <img src="/asset/images/preload_image.png" className={classes.cardImagePreload} alt={'Image formation'}/>}
                    <img src={props.image && props.image} className={classes.cardImage} alt={'Image formation'} onLoad={() => setLoad(true)}/>
                </>

            }

            <div style={{width: '90%', margin: 'auto'}}>
                <p style={{fontSize: 12, textAlign: 'left', color: theme.colorPrimary}}>{props.isEPP ? 'Programme intégré' : 'Formation continue' }</p>
                <p style={{
                    height: sizeView === 'XS' ? 50 : 85,
                    textAlign: 'left',
                    fontWeight: 500, 
                    fontSize: sizeView === 'XS' ? 14 : 16,
                    marginTop: sizeView === 'XS' ? 20 : 10,
                    marginBottom: 5,
                }}>
                    {props.title ? props.title.substring(0, 100) : ''}
                    {props.title && props.title.length > 100 ? '...' : ''}
                </p>
                

                {props.untilAvailable && <p style={{fontSize: 10, fontStyle: 'italic', textAlign: 'left'}}>{props.untilAvailable}</p>}

                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 5, justifyContent: 'space-evenly'}}>
                    <div className={classes.cardHour}>{props.hours && props.hours + ' heures'}</div>
                    {props.financeTypes?.map((financeType) =>
                        <div key={financeType.id} className={classes.cardFinance}>{financeType.label}</div>
                    )}
                </div>
                
                <p style={{position: 'absolute', bottom: 0, right: 10, fontSize: 13, color: props.notAvailable ? '#4f93a5' : theme.colorPrimary}}>
                    {props.notAvailable ? 'Pré-inscription →' : 'En savoir +'}
                </p>
            </div>

            {sizeView !== 'XS' && <LinearProgressMUI className={classes.linearProgress} hidden={load}/>}
        </div>
    );
}

const useStyles = (theme) => makeStyles(({
    box: {
        border: '1px solid #F2F2F2',
        borderRadius: 10,
        background: '#fff',
        position: 'relative',
        cursor: 'pointer',
        transition: 'all .3s ease-in-out',
        '&:hover': {
            transform: 'scale(1.04)'
        }
    },
    cardImagePreload: {
        height: 110,
        width: '100%',
        borderRadius: '10px 10px 0px 0px',
        position: 'absolute',
        top: 0,
        left: 0
    },
    cardImage: {
        display: 'block',
        borderRadius: '10px 10px 0px 0px',
        height: 110,
        width: '100%',
        objectFit: 'cover'
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    cardHour: {
        fontWeight: 500,
        padding: 5,
        width: '40%',
        border: '1px solid #F2F2F2',
        margin: 4,
        fontSize: 14
    },
    cardFinance: {
        fontWeight: 400,
        padding: 5,
        width: '27%',
        border: '1px solid #F2F2F2',
        margin: 4,
        fontSize: 13
    }
}));

export default CardFormation;