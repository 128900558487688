import React from 'react'
import DraggableList from "react-draggable-list";
import { Box, Fade, Grid, IconButton, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import ButtonComponent from "../ButtonComponent";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import activity from "react-useanimations/lib/activity";
import AddIcon from '@material-ui/icons/Add';
import UseAnimations from 'react-useanimations';
import { Delete } from '@material-ui/icons';

function DraggableListComponent(props = {
    title: '',
    list: [],
    onListChange: () => { },
    saveAction: () => { },
    cancelSaveAction: () => { },
    templates: () => { },
    handlerTemplates: {
        template1: '',
        template2: '',
        template3: '',
        templateCheckBox: '',
    },
    setAction: {
        setIsOpen: () => { },
        setList: () => { },
        setIsAction: {
            direction: 'top',
            iconAction: '',
            textAction: '',
            isAction: () => { }
        },
    },
    loading: false,
    errorListMessage: '',
    titleButtonAdd: 'Ajouter',
    buttonTransition: {
        data: () => { },
        isTransition: () => { }
    },
    buttonAllDelete: {
        title: 'Supprimer la liste',
        alertMessage: 'Êtes-vous sur de vouloir tout supprimer ?'
    },
    buttonActionDisable: false,
    buttonStyleOff: false,
    scrollAddOff: false,
    className: {}
}) {
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const [list, setList] = React.useState(props.list);
    const [isAllDelete, setIsAllDelete] = React.useState(false);
    const containerRef = React.useRef();
    const RefScrollBottom = React.useRef(null);

    let randomId = '';
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const lettersLength = letters.length;
    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * lettersLength);
        randomId += letters.charAt(randomIndex);
    }

    const handlerForms = (handler, required) => {
        if (handler) {
            if (required === "required") {
                return Object.values(handler).flatMap(({ form }) =>
                    Object.values(form).filter(({ options }) => options?.validation?.[0] === required).map(({ name }) => name)
                );
            } else {
                return Object.values(handler).flatMap(({ form }) =>
                    Object.values(form).map(({ name, type }) => ({ name, type }))
                );
            }
        }
    }

    const popupErrorAndCheckedError = (handlerNameOptions, handler) => {
        const element = document.querySelector(`[data-id=${randomId}_errorDrag]`);
        element.style.top = '10%';
        element.style.opacity = 1;
        element.style.visibility = 'visible';
        setTimeout(() => {
            element.style.top = '-28%';
            element.style.opacity = 0;
            element.style.visibility = 'hidden';
        }, 3000);
        for (let index in list) {
            let name = '';
            for (let index in handler.template1.form) name = index;
            const elementOrder = document.querySelector(`[data-id="${name + "_" + list[index].order}"]`);
            const elementOrderCheck = document.querySelector(`[data-id="${name + "_" + list[index].order}_check"]`);

            for (let indexHandlerName in handlerNameOptions) {
                const valueList = list[index][handlerNameOptions[indexHandlerName]];
                if (typeof valueList === "string") {
                    elementOrder.style.border = valueList === "" ? '2px solid rgb(220, 53, 69)' : '0px';
                    elementOrder.firstChild.firstChild.style.borderRadius = valueList === "" ? 0 : '5px';
                } else if (typeof valueList === "boolean") {
                    if (elementOrderCheck) {
                        elementOrderCheck.style.border = valueList === false ? '2px solid rgb(220, 53, 69)' : '0px';
                        elementOrderCheck.firstChild.firstChild.style.borderRadius = valueList === false ? 0 : '5px';
                    }
                }
            }
        }
        return true;
    }

    const onCheckSave = () => {
        const handlerNameOptions = handlerForms(props.handlerTemplates, 'required');
        const allHandlersExist = handlerNameOptions.every((handlerName) => list.every((item) => item[handlerName]));
        allHandlersExist ? (props.saveAction(false)) : (props.saveAction(popupErrorAndCheckedError(handlerNameOptions, props.handlerTemplates)));
    }

    const onDelete = (order, name) => {
        const element = document.querySelector(`[data-id="${name + "_" + order}"]`);
        const height = element.offsetParent.offsetHeight;
        element.offsetParent.style.height = height + 'px';
        element.offsetParent.classList.add('fade-out');
        setTimeout(() => {
            element.offsetParent.style.opacity = 0;
            element.offsetParent.style.transform = 'translateX(-100%)';
        }, 300);
        setTimeout(() => {
            element.offsetParent.style.height = 0;
            element.offsetParent.style.margin = 0;
        }, 500);
        setTimeout(() => {
            const filteredLists = list.filter((newList) => newList.order !== order)
            setList(filteredLists)
        }, 1000);
    }

    const _onAdd = () => {
        let maxOrder = 0;
        const newList = [...list];
        for (let index in newList) {
            if (newList[index].order > maxOrder)
                maxOrder = newList[index].order;
        }
        const handlerNames = handlerForms(props.handlerTemplates, "").reduce((acc, { name, type }) => {
            if (type === 'boolean') { acc[name] = false } else acc[name] = '';
            return acc;
        }, {});
        handlerNames["id"] = "";
        handlerNames["order"] = maxOrder + 1;
        handlerNames["add"] = true;
        handlerNames["onDelete"] = onDelete;
        handlerNames["buttonTransition"] = props.buttonTransition ? props.buttonTransition : "";
        newList.push(handlerNames);
        setList(newList);
        if (!props.scrollAddOff) {
            setTimeout(() => RefScrollBottom.current?.scrollIntoView({ behavior: 'smooth' }), 250);
        }
    }

    const _onDeleteAll = () => {
        props.setAction.setList([])
        setIsAllDelete(false)
    }

    React.useEffect(() => {
        for (let index in list) {
            Object.assign(list[index], {
                onDelete: onDelete,
                buttonTransition: props.buttonTransition ? props.buttonTransition : "",
                deleted: false,
            });
        }
        props.setAction.setList(list)
    }, [list]);

    React.useEffect(() => { setList(props.list) }, [props.list]);
    return (
        <Box className={props.className} style={{ width: '100%', height: '100%', margin: '0 auto', display: 'grid', gridTemplateRows: '78px auto 60px', background: '#FFF', position: 'relative', }}>
            <div className='BoxDragTitle-root' style={{ display: 'flex', borderBottom: !props.buttonStyleOff ? '1px solid rgb(206, 200, 200)' : '0' }}>
                {(props.setAction && props.setAction.setIsAction && (props.setAction.setIsAction.direction === 'top')) &&
                    <Box style={{ display: 'flex', margin: 'auto 18px' }}>
                        <Box className={classes.select}>
                            <IconButton disabled={props.loading || isAllDelete} className={classes.settings} onClick={() => { props.setAction.setIsAction.isAction(true) }}>
                                {props.setAction.setIsAction.iconAction}<p>{props.setAction.setIsAction.textAction}</p>
                            </IconButton>
                        </Box>
                    </Box>
                }
                <p className='Title-root' style={{ margin: 'auto 25px', height: 'fit-content', fontSize: 18, fontWeight: 'bold', color: 'rgb(94, 110, 130)' }}>{props.title && props.title}</p>
                <Box style={{ display: 'flex', margin: '0px 19px 0px auto' }}>
                    {props.titleButtonAdd &&
                        <Box className={classes.select}>
                            <IconButton disabled={props.loading || isAllDelete} className={classes.settings} onClick={() => _onAdd()}>
                                <AddIcon style={{ width: 20 }} /><p>{props.titleButtonAdd}</p>
                            </IconButton>
                        </Box>
                    }
                    {props.buttonAllDelete &&
                        <Box className={classes.select} style={{ margin: 'auto 7px auto 0' }}>
                            <IconButton disabled={props.loading || isAllDelete} className={classes.deleted} onClick={() => setIsAllDelete(true)}>
                                <Delete style={{ width: 20 }} /><p>{props.buttonAllDelete.title}</p>
                            </IconButton>
                        </Box>
                    }
                </Box>
            </div>

            <Box style={{
                position: 'relative',
                overflow: 'hidden',
                margin: !props.buttonStyleOff ? 0 : '0 10px 0 10px',
                border: !props.buttonStyleOff ? 'none' : '1px solid rgb(206, 200, 200)',
                borderRadius: !props.buttonStyleOff ? 0 : 5
            }} className='contnaireBoxDrag-root'>
                <div className={classes.boxDrag + ' BoxDrag-root'} style={{
                    filter: isAllDelete ? 'blur(3px)' : 'blur(0px)', overflowY: isAllDelete ? 'hidden' : 'auto',
                    borderLeft: !props.buttonStyleOff ? '1px solid rgb(206, 200, 200)' : 'none',
                }}>
                    {props.loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex', }}>
                                <Box className={classes.loaderContent}>
                                    <Box className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={70} />
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                        :
                        <Box className={classes.item} style={{ padding: '15px 0 0 0', }}>
                            {list.length ?
                                <>
                                    <DraggableList
                                        itemKey={'order'}
                                        template={props.templates}
                                        list={list}
                                        onMoveEnd={(newList) => props.onListChange(newList)}
                                        container={() => containerRef.current}
                                    />
                                    <div ref={RefScrollBottom} />
                                </>
                                :
                                <p className={classes.error + ' Error-root'} ><b>{props.errorListMessage}</b></p>
                            }
                        </Box>
                    }
                </div>

                {props.buttonAllDelete &&
                    <Fade in={isAllDelete} {...{ timeout: 500 }} >
                        <Box className={classes.popupAllDelete + ' popupAllDelete-root'} >
                            <Box className={classes.popupAllDelete} style={{ background: 'transparent' }} onClick={() => setIsAllDelete(false)}></Box>
                            <Box className={classes.boxAllDelete}>
                                <ErrorOutlineIcon className={classes.iconAlert} />
                                <p style={{ fontSize: 21, textAlign: 'center', fontWeight: 'bold', }}>{props.buttonAllDelete.alertMessage}</p>
                                <Grid container style={{ width: '100%' }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <ButtonComponent label={'Non'} color={'#5E6E82'} style={{ width: '50%', padding: 15, borderRadius: 0, height: '100%' }} onClick={() => { setIsAllDelete(false) }} />
                                        <ButtonComponent label={'Oui'} style={{ width: '50%', padding: 15, borderRadius: 0, height: '100%' }} onClick={() => { _onDeleteAll() }} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Fade>
                }

            </Box>

            {!props.buttonActionDisable &&
                <Grid container style={{ width: '100%' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ alignItems: 'center', display: 'flex' }}>
                        <Box className='ActionButton-root' style={{ margin: '0 0 0 auto', padding: props.buttonStyleOff ? 13 : 0, width: props.buttonStyleOff ? 'initial' : '100%', height: props.buttonStyleOff ? 'initial' : '100%', }}>
                            {(props.setAction && props.setAction.setIsOpen) ?
                                <ButtonComponent label={'Annuler'} color={'#5E6E82'} className={!props.buttonStyleOff ? classes.buttonStyle : classes.buttonStyle2}
                                    onClick={() => { props.setAction.setIsOpen(false) }} />
                                : props.cancelSaveAction ?
                                    <ButtonComponent label={'Annuler'} color={'#5E6E82'} className={!props.buttonStyleOff ? classes.buttonStyle : classes.buttonStyle2}
                                        onClick={() => { props.cancelSaveAction() }} />
                                    : ""
                            }
                            {props.saveAction && <ButtonComponent label={'Enregistrer'} className={!props.buttonStyleOff ? classes.buttonStyle : classes.buttonStyle2} onClick={() => { onCheckSave() }} disabled={props.loading || isAllDelete} />}
                            {(props.setAction && props.setAction.setIsAction && (props.setAction.setIsAction.direction === 'bottom')) &&
                                <ButtonComponent
                                    label={<>{props.setAction.setIsAction.iconAction}{props.setAction.setIsAction.textAction}</>}
                                    className={!props.buttonStyleOff ? classes.buttonStyle : classes.buttonStyle2} onClick={() => { props.setAction.setIsAction.isAction() }} disabled={props.loading || isAllDelete} />
                            }
                        </Box>
                    </Grid>
                </Grid>
            }
            <Box data-id={randomId + '_errorDrag'} className={classes.boxErrorPopup + ' ErrorPopup'}>
                <ErrorOutlineIcon className={classes.iconAlert} />
                <p style={{ fontSize: 16, textAlign: 'center', fontWeight: 'bold', }}>Veuillez saisir les champs obligatoire pour enregistrer.</p>
            </Box>
        </Box>
    )
}

const useStyles = (screenReducer) => makeStyles({
    boxDrag: {
        display: 'grid',
        height: '100%',
        overflowX: 'hidden',
        padding: '0px 20px',
        background: '#F7F7F7',
        position: 'relative',
        transitionProperty: 'all',
        transitionDuration: '.3s',
    },
    buttonStyle: {
        width: '50%',
        padding: 15,
        borderRadius: 0,
        height: '100%'
    },
    buttonStyle2: {
        margin: '0 3px',
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    select: {
        border: "1px solid #d7d6e1",
        margin: 'auto 8px auto 0',
        borderRadius: 4,
        background: "#F8FAFC",
        overflow: 'hidden',
        height: 35,
    },
    deleted: {
        padding: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        color: '#FFFFFF',
        transitionProperty: 'all',
        transitionDuration: '.3s',
        '& .MuiIconButton-label': {
            backgroundColor: 'rgb(220, 53, 69)',
            height: '100%',
            justifyContent: 'initial',
            padding: '0px 10px',
            transitionProperty: 'all',
            transitionDuration: '.3s',
            '&:hover': {
                backgroundColor: 'rgb(189, 60, 73)',
            }
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },

    settings: {
        padding: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            transitionProperty: 'all',
            transitionDuration: '.3s',
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            height: '100%',
            justifyContent: 'initial',
            padding: '0px 10px',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    error: {
        color: "#4d4c4c",
        textTransform: "uppercase",
        fontSize: '2vw',
        opacity: 0.1,
        width: "100%",
        textAlign: 'center',
        margin: 'auto',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        '& b': {
            margin: 'auto'
        }
    },
    popupAllDelete: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        background: '#0000004d',
        zIndex: 100,
        top: 0,
        left: 0
    },
    boxAllDelete: {
        maxWidth: 410,
        margin: 'auto',
        padding: 20,
        background: '#FFFFFF',
        borderRadius: 10,
        border: '4px solid #F8BB86',
        position: 'relative',
        zIndex: 120,
    },
    iconAlert: {
        margin: '-30px auto',
        width: 60, height: 60,
        color: 'rgb(248, 187, 134)',
        background: '#FFF',
        borderRadius: 100,
        display: 'block',
        transform: 'translate(0,-20%)',
    },
    item: {
        '& .fade-out': {
            transitionProperty: 'all',
            transitionDuration: '.5s',
            overflow: 'hidden',
        }
    },
    boxErrorPopup: {
        width: 500,
        background: 'rgb(255, 255, 255)',
        position: 'absolute',
        border: '4px solid #F8BB86',
        margin: 'auto',
        transform: 'translate(-50%,-0%)',
        left: '50%',
        top: '-28%',
        opacity: 0,
        visibility: 'hidden',
        borderRadius: 10,
        transitionProperty: 'all',
        transitionDuration: '.5s',
    },

});

export default DraggableListComponent;