import { Box, Grid, Slide, makeStyles } from "@material-ui/core";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import moment from "moment";
import React from "react";
import TitleComponent from "../../../../Component/TitleComponent";
import SelectComponent from "../../../../Component/SelectComponent";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterListIcon from '@material-ui/icons/FilterList';

const DatePickerPeriodeComponent = (props) => {
    const classes = useStyles();
    const [textSearchSlider, setTextSearchSlider] = React.useState('');
    const [period, setPeriod] = React.useState(1);
    const [startAt, setStartAt] = React.useState(moment());
    const [endAt, setEndAt] = React.useState(null);

    const optionStatus = [
        { value: 1, label: 'Journalière' },
        { value: 2, label: 'Hebdomadaire' },
        { value: 3, label: 'Mensuelle' },
        { value: 4, label: 'Annuelle' },
        { value: 5, label: 'Personnalisée par plage' }
    ];
    // Form Search
    const [formSearchStatus, setFormSearchStatus] = React.useState({
        status: {
            name: 'status',
            label: 'Période',
            textHelper: 'Sélectionnez une Période.',
            type: 'integer',
            defaultValue: period,
            options: {}
        },
    });
    const handlerSearchStatus = formHandlerInit(formSearchStatus, setFormSearchStatus);
    React.useEffect(handlerSearchStatus.start, []);

    const [formSearchDate, setFormSearchDate] = React.useState({
        startAt: {
            name: 'startAt',
            label: 'Début',
            textHelper: 'Sélectionnez une date de début.',
            type: 'date',
            defaultValue: '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Fin',
            textHelper: 'Sélectionnez une date de fin.',
            type: 'date',
            defaultValue: '',
            options: {}
        },
    });
    const handlerSearchDate = formHandlerInit(formSearchDate, setFormSearchDate);
    React.useEffect(handlerSearchDate.start, []);

    const getInfo = () => {
        if (endAt && startAt) {
            props.onHandler({
                startAt: startAt.format('YYYY-MM-DD'),
                endAt: endAt.format('YYYY-MM-DD')
            });
        }
    }

    const inputPeriodChange = (value) => {
        setStartAt(moment().startOf('day'));
        setPeriod(parseInt(value));
    }

    const inputSearchChange = (index, value) => {
        handlerSearchDate.setValue(index, value);
        if ((handlerSearchDate.getData().startAt > handlerSearchDate.getData().endAt) && handlerSearchDate.getData().endAt) {
            handlerSearchDate.setError('endAt', 'La date de fin doit pas être inférieur à la date de début')
        } else if (period === 5) {
            handlerSearchDate.setError('endAt', '');
            props.onHandler({
                startAt: handlerSearchDate.getData().startAt,
                endAt: handlerSearchDate.getData().endAt
            })
        }
    };

    // Treatment search form
    const changeSearchSlider = (direction = null) => {
        let newStartAt;
        let newEndAt;

        switch (period) {
            case 1: { // Journalière
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'days');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'days');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('day').format());
                newStartAt = moment(newStartAt.startOf('day').format());

                setTextSearchSlider(newStartAt.format('dddd LL'));
                break;
            }
            case 2: { // Hebdomadaire
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'week');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'week');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('week').format());
                newStartAt = moment(newStartAt.startOf('week').format());

                setTextSearchSlider(newStartAt.format('dddd LL') + ' - ' + newEndAt.format('dddd LL'));
                break;
            }
            case 3: { // Mensuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'month');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'month');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('month').format());
                newStartAt = moment(newStartAt.startOf('month').format());

                setTextSearchSlider(newStartAt.format('MMMM YYYY'));
                break;
            }
            case 4: { // Annuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('year').format());
                newStartAt = moment(newStartAt.startOf('year').format());

                setTextSearchSlider(newStartAt.format('YYYY'));
                break;
            }
            case 5: { // Personnalisée par plage
                newEndAt = endAt;
                newStartAt = startAt;
                break;
            }
            default: {
                newStartAt = null;
                newEndAt = null;
                break;
            }
        }
        setStartAt(newStartAt);
        setEndAt(newEndAt);
    };

    React.useEffect(() => {
        handlerSearchDate.setValue('startAt', startAt);
        handlerSearchDate.setValue('endAt', endAt);
    }, [endAt, startAt]);

    React.useEffect(changeSearchSlider, [period]);
    React.useEffect(() => {
        getInfo()
    }, [startAt]);
    React.useEffect(() => {
        if (period === 5) getInfo();
    }, [endAt]);

    return (
        <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
            <Box>
                <TitleComponent title={<p style={{ margin: 0, display: 'flex', alignItems: 'center' }}><FilterListIcon className={classes.iconH3} />Filtre</p>} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <SelectComponent name={'status'} handler={handlerSearchStatus} options={optionStatus} onChange={(val) => inputPeriodChange(val)} />
                    </Grid>
                    {period !== 5 ?
                        <Grid item xs={12} sm={12} md={8} lg={8} style={{ position: 'relative' }}>
                            <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')} />
                            <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                            <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')} />
                        </Grid> :
                        <>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <DatePickerComponent name={'startAt'} handler={handlerSearchDate} onChange={(val) => inputSearchChange('startAt', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <DatePickerComponent name={'endAt'} handler={handlerSearchDate} onChange={(val) => inputSearchChange('endAt', val)} />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </Slide>
    );
}

const useStyles = makeStyles({
    selectPeriod: {
        '& select': {
            padding: 10
        }
    },
    datepicker: {
        margin: 0,
        width: '100%',
        '& input': {
            padding: '10px 0px 10px 10px'
        },
        '& .MuiInputBase-root': {
            padding: 0
        },
        '& svg': {
            fontSize: 20
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    textSearchSlider: {
        fontSize: 16,
        textAlign: 'center',
        height: 40,
        lineHeight: '40px',
        textTransform: 'capitalize'
    },
})
export default DatePickerPeriodeComponent;