import React from 'react';
import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import CountUp from 'react-countup';

function MultipleCheckboxComponent(props = {
    name: '',
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    },
    options: [],
    line: false,
    disabled: false,
    hasFeedback: false,
    scoreFeedback: '',
    CountUp:false
}) {

    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(data.value ? data.value : []);
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled || props.disabled;

    const onChange = (event) => {
        const changedValue = value.includes(event.target.value) ? value.filter(status => status !== event.target.value) : [...value, event.target.value];
        setValue(changedValue);
        let newForm = { ...props.handler.form };
        newForm[props.name].value = changedValue;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onBlur = () => {
        setFocus(false);
    }
    const onFocus = () => {
        setFocus(true);
    }
    React.useEffect(() => {
        setError(props.handler.form[props.name].error)
    }, [props.handler.form[props.name]].error);

    React.useEffect(() => {
        if (props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <FormControl variant="outlined"
            className={`
                        ${classes.checkbox}
                        ${props.line ? classes.checkboxLine : ''}
                        ${isFocus ? classes.checkboxFocus : error ? classes.checkboxError : value.length > 0 && !disabled ? classes.checkboxValid : ''}
                    `}
        >
            <div>
                <FormLabel component="legend">{data.label}</FormLabel>
                <FormGroup row={props.line}>
                    {
                        props.options.map(data => (
                            <div style={{ display: 'flex', alignItems: 'center', }} className={props.hasFeedback ? data.isCorrect ? classes.checkboxFeedbackValid : '' : ''}>
                                <FormControlLabel
                                    key={data.value}
                                    control={<Checkbox checked={value.includes(data.value)}
                                        value={data.value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        disabled={disabled}
                                    />}
                                    label={data.label}
                                />
                                {props.scoreFeedback != undefined ?<p style={{ margin: 0, width: 'min-content' }} >{props.CountUp ? <><CountUp separator=" " end={props.scoreFeedback['score'][data.value]}/>%</> : ''}</p>: null}
                            </div>
                        ))
                    }
                </FormGroup>
            </div>
            <FormHelperText>{error && !isFocus ? error : data.textHelper}</FormHelperText>
        </FormControl>
    );
}

const useStyles = makeStyles({
    checkbox: {
        width: '100%',
        marginTop: 0,
        '& legend': {
            padding: '10px 0',
            textAlign: 'initial'
        },
        '& .MuiFormHelperText-root': {
            margin: '0 14px',
            fontSize: 10,
            height: 10,
            lineHeight: '10px'
        }
    },
    checkboxLine: {
        '& > div': {
            flexDirection: 'row',
            display: 'flex',
            '& legend': {
                padding: '12px 12px 12px 2px',
            }
        }
    },
    checkboxFocus: {
        '& legend': {
            color: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        }
    },
    checkboxValid: {
        '& legend': {
            color: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        }
    },
    checkboxFeedbackValid: {
        backgroundColor: '#91ef91 !important',
        '& MuiFormControlLabel-root': {
            backgroundColor: '#91ef91 !important'
        }
    },
    checkboxError: {
        '& legend': {
            color: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    }
});

export default MultipleCheckboxComponent;
