import React from 'react'
import Api from '../../../../Api';
import { useDispatch, useSelector } from "react-redux";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { Box, Dialog, DialogActions, DialogContent, Grid, Popover, Typography, makeStyles } from "@material-ui/core";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import ReorderIcon from '@material-ui/icons/Reorder';
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Delete, Edit } from "@material-ui/icons";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import SweetAlert from "sweetalert-react";
import DraggableListComponent from '../../../../Component/DraggableListComponent/DraggableListComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import ButtonComponent from '../../../../Component/ButtonComponent';
import { getRoutePathname } from '../../../../Config/Route';

export default function FormationResource({ props = {} }) {
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(true);
    const [dialog, setDialog] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [listResource, setListResource] = React.useState([]);
    const [optionResources, setOptionResources] = React.useState([]);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [resourceId, setResourceId] = React.useState(null);
    const [formationResourceId, setFormationResourceId] = React.useState(null);

    // FORM
    const [form, setForm] = React.useState({
        resource: {
            id: '',
            name: 'resource',
            label: 'Ressource',
            textHelper: 'Choisissez un ressource.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez un nom surchargé.',
            type: 'text',
            defaultValue: ''
        }
    });

    const orderSave = (list) => {
        Api.post({
            route: 'institution_health_content_manager_formation_formation_resource_order',
            data: { list: list },
            params: { formation: props.match.params.id }
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: "L\'ordre a été changé.",
                        variant: 'success',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    
    // ACTION
    const editAction = (item) => {
        setLoad(true);
        setReadyContent(false);
        if (item) {
            setIsEdition(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'institution_health_content_manager_formation_formation_resource_get',
                params: { id: item.id, formation: props.match.params.id }
            },
                (response) => {
                    handler.setFormLoading(false);
                    setLoad(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data[0]);
                        handler.form.resource.id = data[0].id;
                        handler.form.resource.resourceId = data[0].resource;
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else {
            setIsEdition(false);
            setReadyContent(true);
        }
    }

    const Templates = ({ item, dragHandleProps }) => {
        const { onMouseDown } = dragHandleProps;
        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleClick = (event) => setAnchorEl(event.currentTarget);
        const handleClose = () => setAnchorEl(null);
        const open = Boolean(anchorEl);
        const idPopover = open ? 'simple-popover' : undefined;
        const viewAction = () => {
            return (
                <div style={{ margin: '0 0 0 10px' }}>
                    <IconButton style={{ padding: 0 }} onClick={handleClick}>
                        <MoreHorizIcon style={{ color: "#5E6E82" }} />
                    </IconButton>
                    <Popover
                        id={idPopover}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className={classes.popover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Typography style={{ padding: 8 }}>
                            {getAction()}
                        </Typography>
                    </Popover>
                </div>
            )
        }

        const getAction = () => {
            return (
                <>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => { setDialog(true); editAction(item); setResourceId(item.resourceId); setFormationResourceId(item.id) }}>
                        <Edit style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Modifier</span>
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => { props.history.push(getRoutePathname('institution_health_content_manager_resource', { id: item.resourceId })); }}>
                        <Assignment style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Visualiser</span>
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => { setShowConfirm(true); setResourceId(item.resourceId); setFormationResourceId(item.id) }}>
                        <Delete style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Supprimer</span>
                    </IconButton>
                </>
            )
        }
        
        return (
            <>
                <Box style={{ background: '#FFF', padding: '10px 14px', borderRadius: 10, display: 'flex', alignItems: 'center', }}>
                    <IconButton style={{ padding: 6 }} onMouseDown={(e) => onMouseDown(e)}>
                        <ReorderIcon style={{ color: 'rgb(94, 110, 130)' }} />
                    </IconButton>
                    <p style={{ margin: 0, width: '100%', padding: '4px 10px', borderRight: '2px solid #5E6E82', fontSize: 14, fontWeight: 500 }}>
                        {item.name ? item.name : item.resourceName}
                    </p>
                    {viewAction()}
                </Box>
            </>
        )
    }

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            setLoad(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                let datas = handler.getData();
                Api.post({
                    route: 'institution_health_content_manager_formation_formation_resource_edit',
                    data: datas,
                    params: { formation: props.match.params.id, id: formationResourceId, resource: resourceId }
                }, (response) => {
                    if (response.status === 200) {
                        getResource();
                        setDialog(false);
                        setLoad(false);
                        handler.setFormLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else if (response.status === 400) {
                        setLoading(false)
                        handler.setFormLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'institution_health_content_manager_formation_formation_resource_add',
                    params: { formation: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    if (response.status === 200) {
                        setFormationResourceId(response.data.id)
                        setDialog(false);
                        setLoad(false);
                        getResource();
                        handler.setFormLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else if (response.status === 400) {
                        setLoading(false)
                        handler.setFormLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const getResource = () => {
        setLoading(true);
        if (props.match.params.id) {
            Api.get({
                route: 'institution_health_content_manager_formation_formation_resource_list',
                params: { formation: props.match.params.id }
            }, (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    let data = response.data;
                    for (let index in data) Object.assign(data[index], { formationId: props.match.params.id });
                    setListResource(data);
                } else {
                    setListResource([]);
                }
            })
        }
    }

    const handler = formHandlerInit(form, setForm);
    React.useEffect(() => {
        getResource();
        Api.get({
            route: 'select_resources'
        },
            (response) => {
                if (response && response.data) {
                    setOptionResources(response.data);
                }
            });
        handler.start();
    }, []);
    return (
        <>
            <DraggableListComponent
                list={listResource}
                templates={(item, dragHandleProps) => Templates(item, dragHandleProps)}
                loading={loading}
                onListChange={(newList) => { setListResource(newList); setTimeout(() => orderSave(newList), 1000); }}
                setAction={{
                    setList: (newList) => { setListResource(newList) },
                    setIsAction: {
                        textAction: <p style={{ margin: 0, padding: '0 10px' }}>Ajouter une ressource</p>,
                        iconAction: <AddIcon style={{ width: 20 }} />,
                        direction: 'bottom',
                        isAction: () => { setDialog(true); setIsEdition(false); setResourceId(null); setFormationResourceId(null); handler.reset(); }
                    },
                }}
                errorListMessage={'Aucune ressoures n\'a été ajoutée'}
                className={classes.buttonStyle}
            />
            <Dialog loading={!isReadyContent} open={dialog} fullWidth={true} className={classes.subMenu} onClose={() => { setDialog(false); handler.reset(); }}>
                <h2 style={{ fontsSize: 22, color: 'rgb(94, 110, 130)', fontWeight: 'bold', padding: '16px 24px 6px 16px', margin: 0 }}>{isEdition ? <>Modifier la ressource</> : <>Ajouter une ressource</>} |</h2>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SelectAutocompleteComponent name={'resource'} handler={handler} options={optionResources} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'name'} handler={handler} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handler.reset(); setDialog(false) }} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save()} disabled={isEdition && load} />
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette ressource ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'institution_health_content_manager_formation_formation_resource_delete',
                        params: { id: formationResourceId, formation: props.match.params.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            if (response.status === 200) {
                                getResource();
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Ressource supprimée.',
                                        variant: 'success',
                                    })
                                );
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
const useStyles = (screenReducer) => makeStyles({
    title: {
        fontSize: 18,
        margin: 0,
        padding: '7px 0 20px 0',
        fontWeight: 600,
        color: '#516375',
    },
    buttonStyle: {
        gridTemplateRows: '0px auto 46px !important',
        width: '95% !important',
        height: '100% !important',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        position: 'absolute !important',
        '& .BoxDragTitle-root': {
            border: 'none !important',
            '& .Title-root': {
                fontWeight: '600 !important',
                margin: 'auto 16px !important',
            }
        },
        '& .BoxDrag-root': {
            border: 'none !important',
            borderRadius: '15px 15px 0 0',
            background: '#EFF2F4',
        },
        '& .Error-root': {
            fontSize: '22px !important',
        },
        '& .ActionButton-root .MuiButtonBase-root': {
            width: '100%',
            borderRadius: '0 0 15px 15px',
            padding: 0,
        },
        '& .ErrorPopup': {
            width: '100%',
        }
    },
    iconButton: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        color: '#5E6E82',
        "& span": {
            justifyContent: 'left'
        }
    },
    subMenu: {
        '& .MuiPaper-root': {
            borderRadius: 4,
            flexDirection: 'column',
            margin: 0,
        }
    },
})