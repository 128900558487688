import React from 'react'
import Api from '../../../../Api';
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import TemplatesComponent from '../../../../Component/DraggableListComponent/TemplatesComponent';
import DraggableListComponent from '../../../../Component/DraggableListComponent/DraggableListComponent';

const HandlerDraggableList = () => {
    const [formTemplate1, setFormTemplate1] = React.useState({
        answer: {
            name: 'answer',
            title: 'Réponse',
            textHelper: 'Saisissez une réponse.',
            type: 'text',
            typeInput: 'inputText',
            options: { validation: ['required'] },
            defaultValue: ''
        },
    });
    const [formCheckBox, setFormCheckBox] = React.useState({
        trueORfalse: {
            name: 'trueORfalse',
            label: 'Réponses correct',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {},
        },
    });
    const handlerTemplate1 = formHandlerInit(formTemplate1, setFormTemplate1);
    const handlerCheckBox = formHandlerInit(formCheckBox, setFormCheckBox);
    React.useEffect(handlerTemplate1.start, []);
    React.useEffect(handlerCheckBox.start, []);
    return { handlerTemplate1, handlerCheckBox }
}

const Templates = ({ item, dragHandleProps }) => {
    const draggableListHandlers = HandlerDraggableList();
    return (
        <TemplatesComponent
            item={item}
            dragHandleProps={dragHandleProps}
            handlerTemplate={{
                template1: {
                    handler: draggableListHandlers.handlerTemplate1,
                },
                templateCheckBox: draggableListHandlers.handlerCheckBox,
            }}
            alertMessage={'Êtes-vous sur de vouloir supprimer cette réponse ?'}
        />
    )
}

export default function QuestionAnswer(props) {
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const data = props.data;
    const propsData = props.props;
    const handler = props.handler;
    const draggableListHandlers = HandlerDraggableList();
    const [listAnswerDeleted, setListAnswerDeleted] = React.useState([]);
    const [listAnswer, setListAnswer] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [id, setId] = React.useState(null)

    const save = (error) => {
        const listDeleted = listAnswerDeleted.filter((value) => !listAnswer.some((item) => item.id === value.id));
        if (error || handler.checkError()) {
            console.log('Error')
        } else {
            setLoading(true)
            props.setAction.setLoading(true)
            if (id) {
                Api.post({
                    route: 'institution_health_content_manager_formation_formation_question_answer_action',
                    data: {
                        list: listAnswer,
                        listDeleted: listDeleted,
                        questionText: handler.getData().question,
                    },
                    params: { formation: propsData.match.params.id, question: id }
                }, (response) => {
                    if (response.status === 200) {
                        getAnswer(id)
                        props.setAction.setLoading(false)
                        dispatch(
                            SnackbarOpen({
                                text: 'Résponses enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'institution_health_content_manager_formation_formation_question_add',
                    data: {
                        question: handler.getData().question,
                        order: props.questionOrder,
                        list: props.questionList,
                        listDeleted: props.deletedQuestionList,
                    },
                    params: { formation: propsData.match.params.id }
                }, (response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.id) {
                            data.id = response.data.id;
                            data.add = false;
                            setId(response.data.id)
                            props.loadingQuestion()
                            saveAnswer(response.data, listDeleted)
                        }
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    const saveAnswer = (data, listDeleted) => {
        Api.post({
            route: 'institution_health_content_manager_formation_formation_question_answer_action',
            data: {
                list: listAnswer,
                listDeleted: listDeleted,
            },
            params: { formation: propsData.match.params.id, question: data.id }
        }, (response) => {
            if (response.status === 200) {
                getAnswer(data.id)
                props.setAction.setLoading(false);
                dispatch(
                    SnackbarOpen({
                        text: 'Résponses enregistrée.',
                        variant: 'success',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const getAnswer = (id) => {
        if (id) {
            setLoading(true);
            Api.get({
                route: 'institution_health_content_manager_formation_formation_question_answer_list',
                params: { formation: propsData.match.params.id, question: id }
            }, (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    props.setAction.setLoading(false)
                    let dataAnswer = response.data;
                    setListAnswer(dataAnswer);
                    setListAnswerDeleted(dataAnswer);
                } else {
                    setListAnswer([]);
                    setListAnswerDeleted([]);
                }
            });
        } else {
            setLoading(true);
            props.setAction.setLoading(true)
            setTimeout(() => {
                setLoading(false);
                props.setAction.setLoading(false);
                setListAnswer([]);
                setListAnswerDeleted([]);
            }, 1000);
        }
    }

    React.useEffect(() => { props.setCheckedList(listAnswer) }, [listAnswer])
    React.useEffect(() => { if (props.reload) getAnswer(data.id) }, [props.reload])
    React.useEffect(() => { setId(data.id); getAnswer(data.id) }, [data.id])
    return (
        <DraggableListComponent
            list={listAnswer}
            templates={Templates}
            loading={loading}
            onListChange={(newList) => { setListAnswer(newList) }}
            setAction={{
                setList: (newList) => { setListAnswer(newList) },
                setIsAction: {
                    direction: 'top',
                    textAction: 'Voir les commentaires',
                    isAction: (newList) => { props.setAction.setIsListComment(newList) },
                }
            }}
            handlerTemplates={{
                template1: draggableListHandlers.handlerTemplate1,
                templateCheckBox: draggableListHandlers.handlerCheckBox,
            }}
            titleButtonAdd={'Ajouter une réponse'}
            errorListMessage={'Aucune réponse a été ajouter'}
            buttonAllDelete={{
                title: 'supprimer les réponses',
                alertMessage: 'Êtes-vous sur de vouloir supprimer toute les réponses ?',
            }}
            saveAction={save}
            buttonStyleOff
            className={classes.dragStyle}
        />
    )
}

const useStyles = (screenReducer) => makeStyles({
    dragStyle: {
        boxShadow: '0px 0px 11px #00000082', borderRadius: 8,
        background: '#FFF', width: '100%', height: '100%', top: 0, position: 'absolute',
    },
});