import React, { useEffect } from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import { inArray } from "../../../Tool/ArrayCommon";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit, PhotoSizeSelectLargeTwoTone, Tune } from "@material-ui/icons";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";
import SelectComponent from "../../../Component/SelectComponent";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { getRoutePathname } from "../../../Config/Route";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import SweetAlert from "sweetalert-react";
import { TableReload } from "../../../Action/TableAction";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch, useSelector } from "react-redux";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import {hasRight} from "../../../Tool/UserTool";
import SelectAutocompleteComponent from "../../../Component/SelectAutocompleteComponent";
import ButtonComponent from "../../../Component/ButtonComponent";

let VirtualClassChecked = [];
function VirtualClassroomList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionAddYears, setOptionAddYears] = React.useState([]);
    const [optionFormer, setOptionFormer] = React.useState([]);
    const [optionAddFormer, setOptionAddFormer] = React.useState([]);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionsFormation, setOptionsFormation] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [dialog, setDialog] = React.useState(false);
    const [isCrmAdmin, setIsCrmAdmin] = React.useState(true);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);
    const [optionPeriod, setOptionPeriod] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [fullSessions, setFullSessions] = React.useState(false);

    const [form, setForm] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Choisissez une année.',
            type: 'integer',
            defaultValue: parseInt(moment().format('YYYY')),
            options: {validation: ['required'],}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Choisissez l\'organisme de facturation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez une formation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        period: {
            name: 'period',
            label: 'Crénaux',
            textHelper: 'Choisissez les crénaux de cette classe.',
            type: 'array',
            defaultValue: '',
            options: {validation: ['array', 'required']}
        },
        former: {
            name: 'former',
            label: 'Formateur',
            textHelper: 'Choisissez un formateur',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Indiquez la durée de cette classe virtuelle',
            type: 'integer',
            options: {validation: ['required'],}
        },
        meetingAt: {
            name: 'meetingAt',
            label: 'Date de la réunion ZOOM',
            textHelper: "Saisissez la date de la réunion ZOOM",
            type: 'date',
            defaultValue: '',
            options: {validation: ['date','required']}
        },
    });
    const handler = formHandlerInit(form, setForm);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "formerName",
            label: "Formateur",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAtText",
            label: "Debut de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAtText",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "meetingAtText",
            label: "Date classe virtuelle",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "registrations",
            label: "Participants",
            options: {filter: false, sort: false}
        },
        {
            name: "active",
            label: "Activer",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false, }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('content_manager_virtual_classroom', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        formationName: {
            name: 'formationName',
            label: 'Formation',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].formationName ? cacheTableServer['content_manager_virtual_classroom_list'].formationName : 'all',
            options: {}
        },
        formerName: {
            name: 'formerName',
            label: 'Formateurs',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].formerName ? cacheTableServer['content_manager_virtual_classroom_list'].formerName : 'all',
            options: {}
        },
        sessionStartAtText: {
            name: 'sessionStartAtText',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].sessionStartAtText ? cacheTableServer['content_manager_virtual_classroom_list'].sessionStartAtText : '',
            options: {}
        },
        sessionEndAtText: {
            name: 'sessionEndAtText',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].sessionEndAtText ? cacheTableServer['content_manager_virtual_classroom_list'].sessionEndAtText : '',
            options: {}
        },
        meetingAtText: {
            name: 'meetingAtText',
            label: 'Date de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].meetingAtText ? cacheTableServer['content_manager_virtual_classroom_list'].meetingAtText : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].year ? cacheTableServer['content_manager_virtual_classroom_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['content_manager_virtual_classroom_list'] && cacheTableServer['content_manager_virtual_classroom_list'].financeType ? cacheTableServer['content_manager_virtual_classroom_list'].financeType : 'all',
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['content_manager_virtual_classroom_list'] = 0;
        cacheTableServer['content_manager_virtual_classroom_list'].page = 0;
        cacheTableServer['content_manager_virtual_classroom_list'][index] = value;
        dispatch(TableReload('content_manager_virtual_classroom_list'))
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
            route: 'select_organisms',
            data: {'isFull': true, 'idForced': props.organism}
        },
        (response) => {
            if (response && response.data) {
                let data = [];
                for (let index in response.data) {
                    if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                        data.push(response.data[index]);
                    }
                }
                if (data.length > 0) {
                    setOptionOrganisms(data);
                }
            }
        });
    }, []);
    React.useEffect(() => {
        if (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN')) {
            setIsCrmAdmin(false);
        }
    }, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des classes virtuelles',
            context: 'Gestion de contenu',
            description: ''
        });

        // Year
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        let yearsAddTMP = [
            {label: currentYear + 1, value: currentYear + 1}, 
            {label: currentYear, value: currentYear}, 
            {label: currentYear - 1, value: currentYear - 1}
        ];
        setOptionYears(yearsTMP);
        setOptionAddYears(yearsAddTMP);

        Api.get({
            route: 'select_formers'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    let dataAdd = [...response.data];
                    setOptionFormer(data);
                    setOptionAddFormer(dataAdd);
                }
            });

        Api.get({
            route: 'select_formations'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormation(data);
                }
            });

        Api.get({
            route: 'select_finance_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFinanceType(data);
                }
            });
        Api.get({
            route: 'select_virtual_classroom_period'
        },
            (response) => {
                if (response && response.data) {
                    let data = [...response.data];
                    setOptionPeriod(data);
                }
            });

        setReadyContent(true);
    }, []);

    const save = (id, checked) => {
        Api.post({
            route: 'content_manager_virtual_classroom_active',
            params: { id: id },
            data: { active: checked }
        },
            (response) => {
            console.log(response.status);
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Classe virtuelle modifiée.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const saveAdd = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            const dataTMP = handler.getData();

            Api.post({
                route: 'content_manager_virtual_classroom_add',
                
                data: {
                    sessionFormation: dataTMP.sessionFormation,
                    period: dataTMP.period,
                    former: dataTMP.former,
                    duration: dataTMP.duration,
                    meetingAt: dataTMP.meetingAt,
                } 
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        handler.setFormLoading(false)
                        dispatch(
                            TableReload('content_manager_virtual_classroom_list'),
                            SnackbarOpen({
                                text: 'Classe virtuelle créée.',
                                variant: 'success',
                            }),
                        );
                        setDialog(false);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
        
    };


    const getActionCheck = (data, checked) => {

        return (
            <Checkbox
                key={data + '-' + new Date().getTime()}
                checked={checked}
                onChange={() => {
                    if (inArray(data, VirtualClassChecked)) {
                        let index = VirtualClassChecked.indexOf(data);
                        if (index > -1) {
                            VirtualClassChecked.splice(index, 1);
                            save(data, false)
                            dispatch(TableReload('content_manager_virtual_classroom_list'));
                        }
                    } else {
                        VirtualClassChecked.push(data);
                        save(data, true);
                        dispatch(TableReload('content_manager_virtual_classroom_list'));
                    }
                }}
            />
        );
    };
    const getDatas = (resolve, filters) => {
        Api.get({
            route: 'content_manager_virtual_classroom_list', data: filters
        },
            (response) => {
                let data = response.data.data;
                for (let index in data) {

                    data[index].formerName = data[index].formerLastname + "  " + data[index].formerFirstname;
                    data[index].sessionStartAtText = moment(data[index].sessionStartAt).format('DD/MM/YYYY');
                    data[index].sessionEndAtText = moment(data[index].sessionEndAt).format('DD/MM/YYYY');
                    data[index].meetingAtText = moment(data[index].meetingAt).format('DD/MM/YYYY');
                    data[index].action = getAction(data[index]);

                    if (data[index].TrueFalse === true) VirtualClassChecked.push(data[index].id);
                    data[index].active = getActionCheck(data[index].id, data[index].TrueFalse);

                }
                resolve(data, response.data.count);
            });
    }

    // Save
    const changeYear = (value, mustReload, formationId = '', formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['year'].value = value;
        newForm['year'].error = handler.getErrorByField(newForm['year']);
        handler.set(newForm);
        if (mustReload) loadFormation(formationId, formationSessionId);
    };
    const changeOrganism = (value, mustReload, formationId = '', formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['organism'].value = value;
        newForm['organism'].error = handler.getErrorByField(newForm['organism']);
        handler.set(newForm);
        if (mustReload) loadFormation(formationId, formationSessionId);
    };
    const loadFormation = (formationId, formationSessionId) => {
        let organism = form['organism'].value;

        if (organism) {
            changeFormation(formationId, formationSessionId);
            handler.setFormLoading(true);
            Api.get({
                    route: 'content_manager_virtual_classroom_formation_list',
                    data: {organism: organism}
                },
                (response) => {
                    if (response && response.data) {
                        setOptionsFormation(response.data);
                    }
                    handler.setFormLoading(false);
                });
        }
        else {
            changeFormation('', '');
        }
    };
    const changeFormation = (value, formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['formation'].value = value;
        newForm['formation'].error = value !== '' ? handler.getErrorByField(newForm['formation']) : '';
        handler.set(newForm);

        loadSession(fullSessions, formationSessionId);
    };
    const loadSession = (full = false, formationSessionId = '') => {
        setFullSessions(full);

        let formationIdTMP = form['formation'].value;
        let yearTMP = form['year'].value;

        let newForm = {...handler.form};
        newForm['sessionFormation'].value = formationSessionId;
        newForm['sessionFormation'].error = formationSessionId !== '' ? handler.getErrorByField(newForm['sessionFormation']) : '';
        handler.set(newForm);

        if (formationIdTMP) {
            handler.setFormLoading(true);
            Api.get({
                    route: 'content_manager_virtual_classroom_sessions_formation_list',
                    data: {full: full ? 1 : 0, formation: formationIdTMP, year: yearTMP}
                },
                (response) => {
                    handler.setFormLoading(false);
                    if (response && response.data) {
                        setOptionsSessionFormation(response.data);
                    }
                });
        }
        else {
            setOptionsSessionFormation([]);
        }
    };
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'sessionStartAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionStartAtText', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'sessionEndAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionEndAtText', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'meetingAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('meetingAtText', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'formerName'} handler={handlerSearch} options={optionFormer} onChange={(val) => inputSearchChange('formerName', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectAutocompleteComponent name={'formationName'} handler={handlerSearch} options={optionFormation} onChange={(val) => inputSearchChange('formationName', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceType} onChange={(val) => inputSearchChange('financeType', val)} />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br />
            <TableComponent
                id={'content_manager_virtual_classroom_list'}
                title={'Classes virtuelles'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une classe virtuelle',
                    onClick: () => {
                        setDialog(true);
                        handler.reset();
                    }
                }}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        formerName: (datas.formerName === 'all') ? '' : datas.formerName,
                        sessionStartAt: datas.sessionStartAtText ?? '',
                        sessionEndAt: datas.sessionEndAtText ?? '',
                        meetingAt: datas.meetingAtText ?? '',
                        formationName: (datas.formationName === 'all') ? '' : datas.formationName,
                        financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                    };
                    getDatas(resolve, filters)
                }}
                search={false}
            />
            <br />
            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={actionRow.firstname + ' ' + actionRow.lastname + '\nÊtes-vous sur de vouloir supprimer cet classe virtuelle ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_virtual_classroom_delete',
                        params: { id: actionRow.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Classe virtuelle supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_virtual_classroom_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
            <Dialog open={dialog} disabled={isCrmAdmin} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter une class virtuelle</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} md={12} lg={3} xl={3}>
                            <SelectComponent name={'year'} handler={handler} options={optionAddYears} onChange={(value) => changeYear(value, true)}/>
                        </Grid>
                        <Grid item xs={12} sm={5} md={12} lg={3} xl={3}>
                            <MultipleSelectComponent name={'period'} handler={handler} options={optionPeriod}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <SelectComponent name={'former'} handler={handler} options={optionAddFormer}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <TextFieldComponent name={'duration'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={5} md={12} lg={3} xl={3}>
                            <SelectComponent name={'organism'} handler={handler} options={optionOrganisms} onChange={(value) => changeOrganism(value, true)}/>
                        </Grid>
                        <Grid item xs={12} sm={5} md={12} lg={3} xl={3}>
                            <SelectAutocompleteComponent name={'formation'} handler={handler} options={optionsFormation} onChange={changeFormation}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation} nullable={true}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <DatePickerComponent name={'meetingAt'} handler={handler}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {fullSessions ?
                        <ButtonComponent color={'#db770a'} label={'Cacher les sessions passées'} onClick={() => loadSession(false)} disabled={loading}/> :
                        <ButtonComponent color={'#28a745'} label={'Afficher les sessions passées'} onClick={() => loadSession(true)} disabled={loading}/>
                    }
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} disabled={loading} onClick ={saveAdd}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}
const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});
export default VirtualClassroomList;
