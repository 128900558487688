import { Box, IconButton, LinearProgress, Tooltip, makeStyles } from "@material-ui/core";
import { theme } from "../../../../App";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import { formationChapterImage, learnerHomeFormationImageBanner } from "../../../../Api";
import React from "react";
import { useSelector } from "react-redux";
import { Rating } from "@material-ui/lab";

const CardComponent = (props) => {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);

    const hourBySecond = (duration) => {
        const minutes = Math.floor((duration) / 60);
        const seconds = duration % 60;
        return minutes != 0 ? minutes + ' min' : seconds != 0 ? seconds + ' s' : '0s';
    }

    React.useEffect(() => {
        setSmallView(screenReducer.size <= 698);
    }, [screenReducer.size]);

    return (
        <Box onClick={() => { if (props.active) {props.onClick();} }} className={classes.cardRoot} style={isSmallView ? { cursor: props.active ? 'pointer' : 'default' } : { cursor: props.active ? 'pointer' : 'default', gridTemplateColumns: 'max-content auto max-content', }}>
            <Box className={`${props.active || props.completed ? classes.pictureRoot : classes.pictureLockRoot}`} style={{ margin: 'auto' }}>
                <div className={`${classes.lockIconRoot} ${props.completed ? classes.lockIconCompletedRoot : props.active ? classes.lockIconActiveRoot : classes.lockIconOffRoot}`}>
                    {props.completed ? <DoneIcon /> : props.active ? <LockOpenIcon /> : <LockIcon style={{ fontSize: 50 }} />}
                </div>
                <img src={
                    props.image ? formationChapterImage + props.image :
                     props.type === 'quiz' ? '/asset/images/registration-qcm.jpg' :
                     props.type === 'download'? '/asset/images/registration-download.jpg' :
                      learnerHomeFormationImageBanner + props.data.formation.image} />
            </Box>

            <Box style={{ padding: '0px 20px', color: '#5F6577', display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box className={classes.descriptionRoot}>
                    <p style={{ fontSize: 18, padding: '15px 0 0 0' }}>{props.title ? props.title : '-'}</p>
                    {props.duration != 0 &&
                        <p style={{ opacity: '59%', display: 'flex', alignItem: 'center', width: 'fit-content', marginLeft: 10, fontSize: 13 }}>
                            <KeyboardReturnIcon style={{ transform: 'scaleX(-1)', fontSize: 20 }} />{`temps : ${hourBySecond(props.duration)}`}
                        </p>
                    }
                </Box>
                <Box style={{ margin: 'auto 0 0 0', display: 'grid', gridTemplateColumns: 'auto max-content', gridGap: props.rating != undefined ? 20 : 0 }}>
                    <Box className={classes.timeSpentRoot}>
                        <Box style={{ display: 'flex', alignItems: 'center', gap: 5, flexWrap: 'wrap' }}>
                            {props.type === 'download' ? <p style={{ color: theme.colorPrimary }}>Télécharger</p> :
                                (props.type === 'quiz' && !props.completed && props.active) ?
                                    <p style={{ color: theme.colorPrimary }}>Répondre au questionnaire</p> :
                                    (props.completed ? <p style={{ color: theme.colorPrimary }}>Module terminé</p> :
                                        props.active ? <p style={{ color: theme.colorPrimary }}>Continuer ce module...</p> :
                                            <p>Terminé le module en cours</p>)
                            }
                            {(props.score > 0) && <p style={{ color: '#5F6577', fontSize: 13, }}>
                                {'| Score : ' + props.score + ' %'}
                            </p>}
                        </Box>
                        <hr style={{ height: 7, border: 'none', background: theme.colorPrimary + '33', borderRadius: 100, margin: '3px 0' }} />
                        {/*<p>{`Temps passé : ${props.timeSpent ? props.timeSpent : 0} min`}</p>
                        <div className={classes.progression}>
                            <LinearProgress style={{ width: '100%', padding: 2, margin: '7px 0' }} variant="determinate" value={props.timeSpent * 100 / props.duration} />
                        </div>*/}
                    </Box>
                    {props.rating != undefined &&
                        <Tooltip title={'Note attribuée sur 5 par les apprenants.'} placement="left" >
                            <Box className={classes.ratingRoot}>
                                <Rating
                                    name="hover-feedback"
                                    value={props.rating}
                                    precision={1}
                                    readOnly
                                />

                            </Box>
                        </Tooltip>
                    }
                </Box>
            </Box>
            {props.active &&
                <Box style={{ height: '100%' }}>
                    <IconButton
                        className={classes.iconButton}
                        style={isSmallView ? { width: '100%', borderRadius: '0 0 12px 12px' } : {}}
                        onClick={() => { props.onClick() }}>
                        {props.type === 'download' ? <><GetAppIcon /></> : <span className={isSmallView && classes.access}>{isSmallView ? 'Accéder ' : ''}<ArrowForwardIosIcon /></span>}
                    </IconButton>
                </Box>
            }
        </Box>
    )
}
const useStyles = (theme) => makeStyles({
    cardRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'grid',
        padding: 5,
        width: '90%',
        margin: '25px auto',
        background: '#FFF',
        alignItems: 'center'
    },
    pictureRoot: {
        background: '#393939',
        width: 210,
        height: 120,
        borderRadius: 11,
        position: 'relative',
        overflow: 'hidden',
        '& img': {
            position: 'absolute',
            width: '110%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
        },
        '&::before': {
            content: "' '",
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#39393914',
            zIndex: 2
        }
    },
    pictureLockRoot: {
        background: '#393939',
        width: 210,
        height: 120,
        borderRadius: 11,
        position: 'relative',
        overflow: 'hidden',
        '& img': {
            position: 'absolute',
            width: '110%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
        },
        '&::before': {
            content: "' '",
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#39393996',
            zIndex: 2
        }
    },
    descriptionRoot: {
        '& p': {
            margin: 0,
            fontWeight: 550,
        }
    },
    timeSpentRoot: {
        '& p': {
            margin: 0,
            fontWeight: 550,
            display: 'flex',
            alignItem: 'center',
            fontSize: 13
        }
    },
    ratingRoot: {
        display: 'flex',
        '& .MuiRating-root': {
            alignItems: 'center',
            border: '#E5E9F0 solid 1px',
            borderRadius: 4,
            padding: '0 10px',
            color: theme.colorPrimary,
            fontSize: 17,
        },
        '& .MuiRating-iconEmpty': {
            color: theme.colorPrimary + '66'
        }
    },
    iconButton: {
        padding: '0 10px',
        height: '100%',
        borderRadius: '0 12px 12px 0px',
        background: '#F6F6F6'
    },
    lockIconRoot: {
        position: 'absolute',
        color: '#FFF',
        zIndex: 2,
    },
    lockIconActiveRoot: {
        background: theme.colorPrimary,
        padding: 1,
        borderRadius: 7,
        top: 6,
        left: 5,
        display: 'flex',
        border: '2px solid',
    },
    lockIconCompletedRoot: {
        background: '#28a745',
        padding: 1,
        borderRadius: 7,
        top: 6,
        left: 5,
        display: 'flex',
        border: '2px solid',
    },
    lockIconOffRoot: {
        background: 'transparent',
        transform: 'translate(-50%,-50%)',
        left: '50%',
        top: '50%'
    },
    progression: {
        fontSize: 15,
        '& .MuiLinearProgress-root': {
            height: 3,
            borderRadius: 10
        },
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: theme.colorPrimary + '66'
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: theme.colorPrimary
        }
    },
    access: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        padding: 10,
        color: 'rgb(95, 101, 119)',
        opacity: 0.7
    }
})
export default CardComponent;