import React from 'react';
import { CircularProgress, makeStyles } from "@material-ui/core";
import BackupIcon from '@material-ui/icons/Backup';

function UploadComponent(props = {}) {
    const classes = useStyles();
    const fileInput = React.createRef();
    const data = props.handler.form[props.name];

    const [value, setValue] = React.useState('');
    const [label, setLabel] = React.useState(data.label);
    const [error, setError] = React.useState(data.error);
    const [src, setSrc] = React.useState('');

    const inputOnChange = () => {
        if (fileInput.current && fileInput.current.files[0]) {
            setLabel(fileInput.current.files[0].name);
            setSrc(URL.createObjectURL(fileInput.current.files[0]));
        } else {
            setLabel(data.label);
        }

        let newForm = { ...props.handler.form };
        newForm[props.name].value = fileInput.current.files[0];
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        setValue(fileInput.current.files[0]);
        props.handler.set(newForm);
    };


    React.useEffect(() => {
        setError(props.handler.form[props.name].error);
    }, [props.handler.form[props.name].error]);

    React.useEffect(() => {
        if (props.preview) setSrc(props.preview);
    }, [props.preview]);

    return (
        <>
            <label htmlFor={'file_' + props.name}
                style={{
                    border: data.loading ? '3px dashed rgba(0, 0, 0, 0.38)' :
                        (error ? '3px dashed #982525' :
                            ((value || src) ? '3px dashed #008000' :
                                '3px dashed #5E6E82'))
                }}
                className={classes.fileContent}
            >

                <BackupIcon
                    style={{
                        color: data.loading ? 'rgba(0, 0, 0, 0.38)' :
                            (error ? '#982525' :
                                ((value || src) ? '#008000' :
                                    '#5E6E82'))
                    }}
                    className={src ? classes.fileIconPreview : classes.fileIcon}
                />

                <p
                    style={{
                        color: data.loading ? 'rgba(0, 0, 0, 0.38)' :
                            (error ? '#982525' :
                                ((value || src) ? '#008000' :
                                    '#5E6E82'))
                    }}
                    className={src ? classes.fileTextPreview : classes.fileText}
                >
                    {label}
                </p>

                {src && <img width="70px" height="70px" src={src} alt='image preview' onError={() => { setSrc(null) }} />}

                {error && <p style={{ color: '#982525', fontSize: 11, margin: 0 }}>{error}</p>}

                {data.loading && <CircularProgress className={classes.fileLoader} thickness={0.8} style={{ height: 56, width: 56 }} />}

            </label>

            <input disabled={data.loading} id={'file_' + props.name} type="file" hidden={true} ref={fileInput} onChange={inputOnChange} accept={props.accept} />
        </>
    );
}

const useStyles = makeStyles({
    fileContent: {
        order: '3px dashed rgb(204, 204, 204)',
        height: 130,
        width: '100%',
        display: 'block',
        textAlign: 'center',
        cursor: 'pointer',
        position: 'relative'
    },
    fileIcon: {
        marginTop: 28,
        height: 40,
        width: 40
    },
    fileIconPreview: {
        marginTop: 10,
        height: 20,
        width: 20
    },
    fileText: {
        margin: 5
    },
    fileTextPreview: {
        margin: 0,
        fontSize: 13
    },
    fileLoader: {
        color: "rgba(0, 0, 0, 0.38)",
        margin: '0 auto',
        display: 'block',
        position: 'absolute',
        top: 21,
        left: 'calc(50% - 28px)',
    }
});

export default UploadComponent;
