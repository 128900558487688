import React from 'react'
import Api, { formationChapterImage } from '../../../Api';
import { useDispatch, useSelector } from "react-redux";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Box, Dialog, Popover, Typography, makeStyles } from "@material-ui/core";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import ReorderIcon from '@material-ui/icons/Reorder';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import SweetAlert from "sweetalert-react";
import FormationChapterCourse from "./FormationChapterCourse"
import DraggableListComponent from '../../../Component/DraggableListComponent/DraggableListComponent';

export default function FormationChapterV2({ props = {} }) {
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const [loading, setLoading] = React.useState(true);
    const [dialog, setDialog] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [listChapter, setListChapter] = React.useState([]);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [chapterId, setChapterId] = React.useState(null);
    const [formationChapterId, setFormationChapterId] = React.useState(null);
    const [image, setImage] = React.useState('');

    // FORM
    const [form, setForm] = React.useState({
        chapter: {
            id: '',
            chapterId: '',
            name: 'chapter',
            label: 'Chapitre',
            textHelper: 'Choisissez un chapitre.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Saisissez la durée du chaptire dans la formation.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        image: {
            name: 'image',
            label: 'Image',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        name: {
            name: 'name',
            label: 'Nom surchargé',
            textHelper: 'Saisissez un nom surchargé.',
            type: 'text',
            defaultValue: ''
        }
    });

    // FORM
    const [formChapter, setFormChapter] = React.useState({
        chapter: {
            name: 'name',
            label: 'Chapitre',
            textHelper: 'Saisissez le nom du chapitre.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });

    const orderSave = (list) => {
        Api.post({
            route: 'content_manager_formation_formation_chapter_order',
            data: {
                list: list,
            },
            params: { formation: props.match.params.id }
        }, (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: "L\'ordre a été changé.",
                        variant: 'success',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    // ACTION
    const getData = (item) => {
        setReadyContent(false);
        if (item) {
            setIsEdition(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'content_manager_formation_formation_chapter_get',
                params: { id: item.id, formation: props.match.params.id }
            },
                (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let data = response.data[0];
                        setImage(formationChapterImage + data.image);
                        handler.setDataApi(data);
                        handler.form.chapter.id = data.id;
                        handler.form.chapter.chapterId = data.chapter;
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else {
            setIsEdition(false);
            setReadyContent(true);
        }
    }

    const Templates = ({ item, dragHandleProps }) => {
        const { onMouseDown } = dragHandleProps;
        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleClick = (event) => setAnchorEl(event.currentTarget);
        const handleClose = () => setAnchorEl(null);
        const open = Boolean(anchorEl);
        const idPopover = open ? 'simple-popover' : undefined;
        const viewAction = () => {
            return (
                <div style={{ margin: '0 0 0 10px' }}>
                    <IconButton style={{ padding: 0 }} onClick={handleClick}>
                        <MoreHorizIcon style={{ color: "#5E6E82" }} />
                    </IconButton>
                    <Popover
                        id={idPopover}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className={classes.popover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Typography style={{ padding: 8 }}>
                            {getAction()}
                        </Typography>
                    </Popover>
                </div>
            )
        }
        const getAction = () => {
            return (
                <>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => { setDialog(true); getData(item); setChapterId(item.chapterId); setFormationChapterId(item.id) }}>
                        <Edit style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Modifier</span>
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => { setShowConfirm(true); setChapterId(item.chapterId); setFormationChapterId(item.id) }}>
                        <Delete style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Supprimer</span>
                    </IconButton>
                </>
            )
        }
        const temps = () => {
            const heures = Math.floor(item.duration / 3600);
            const minutes = Math.floor((item.duration % 3600) / 60);
            const secondes = item.duration % 60;

            let resultat = '';

            if (heures) {
                resultat += heures + 'h ';
            }

            if (minutes) {
                resultat += minutes + 'min ';
            }

            if (secondes) {
                resultat += secondes + 's';
            }

            return resultat;
        };
        return (
            <>
                <Box style={{ background: '#FFF', padding: '10px 14px', borderRadius: 10, display: 'flex', alignItems: 'center', }}>
                    <IconButton style={{ padding: 6 }} onMouseDown={(e) => onMouseDown(e)}>
                        <ReorderIcon style={{ color: 'rgb(94, 110, 130)' }} />
                    </IconButton>
                    <p style={{ margin: 0, width: '100%', padding: '4px 10px', borderRight: '2px solid #5E6E82', fontSize: 14, fontWeight: 500 }}>
                        {item.name ? item.name : item.chapterName}<br />
                        {(item.duration || item.duration < 0) && <span style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}><KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,3%) scaleX(-1)" }} /><b>Durée</b> {': ' + temps()} </span>}
                    </p>
                    {viewAction()}
                </Box>
            </>
        )
    }

    const getChapter = () => {
        setLoading(true);
        if (props.match.params.id) {
            Api.get({
                route: 'content_manager_formation_formation_chapter_list',
                params: { formation: props.match.params.id }
            }, (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    let data = response.data;
                    for (let index in data) Object.assign(data[index], { formationId: props.match.params.id });
                    setListChapter(data);
                } else {
                    setListChapter([]);
                }
            })
        }
    }

    const handler = formHandlerInit(form, setForm);
    const handlerChapter = formHandlerInit(formChapter, setFormChapter);
    React.useEffect(() => { handler.start(); handlerChapter.start(); getChapter(); }, []);
    React.useEffect(() => {
        if (!dialog) setImage('')
    }, [dialog]);
    return (
        <>
            <DraggableListComponent
                list={listChapter}
                title={'Chapitres'}
                templates={(item, dragHandleProps) => Templates(item, dragHandleProps)}
                loading={loading}
                onListChange={(newList) => { setListChapter(newList); setTimeout(() => orderSave(newList), 1000); }}
                setAction={{
                    setList: (newList) => { setListChapter(newList) },
                    setIsAction: {
                        textAction: <p style={{ margin: 0, padding: '0 10px' }}>Ajouter un chapitre</p>,
                        iconAction: <AddIcon style={{ width: 20 }} />,
                        direction: 'bottom',
                        isAction: () => { setDialog(true); setIsEdition(false); setChapterId(null); setFormationChapterId(null); handlerChapter.reset(); handler.reset(); }
                    },
                }}
                errorListMessage={'Aucun chapitre n\'a été ajoutée'}
                className={classes.buttonStyle}
            />

            <Dialog loading={!isReadyContent} open={dialog} fullWidth={true} className={classes.subMenu} onClose={() => { setDialog(false); setImage(''); handlerChapter.reset(); handler.reset(); }}>
                <FormationChapterCourse
                    props={props}
                    setIsOpen={setDialog}
                    setIsEdition={setIsEdition}
                    setChapterId={setChapterId}
                    setFormationChapterId={setFormationChapterId}
                    isEdition={isEdition}
                    handler={handler}
                    handlerChapter={handlerChapter}
                    getChapter={() => getChapter()}
                    chapterId={chapterId}
                    image={image}
                    formationChapterId={formationChapterId} />
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer ce chapitre ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_formation_formation_chapter_delete',
                        params: { id: formationChapterId, formation: props.match.params.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            if (response.status === 200) {
                                getChapter();
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Chapitre supprimée.',
                                        variant: 'success',
                                    })
                                );
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
const useStyles = (screenReducer) => makeStyles({
    title: {
        fontSize: 18,
        margin: 0,
        padding: '7px 0 20px 0',
        fontWeight: 600,
        color: '#516375',
    },
    buttonStyle: {
        gridTemplateRows: '0px auto 46px !important',
        width: '95% !important',
        height: '100% !important',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        position: 'absolute !important',
        '& .BoxDragTitle-root': {
            border: 'none !important',
            '& .Title-root': {
                fontWeight: '600 !important',
                margin: 'auto 16px !important',
            }
        },
        '& .BoxDrag-root': {
            border: 'none !important',
            borderRadius: '15px 15px 0 0',
            background: '#EFF2F4',
        },
        '& .Error-root': {
            fontSize: '22px !important',
        },
        '& .ActionButton-root .MuiButtonBase-root': {
            width: '100%',
            borderRadius: '0 0 15px 15px',
            padding: 0,
        },
        '& .ErrorPopup': {
            width: '100%',
        }
    },
    iconButton: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        color: '#5E6E82',
        "& span": {
            justifyContent: 'left'
        }
    },
    subMenu: {
        '& .MuiPaper-root': {
            width: screenReducer.screen === 'XL' ? '60%' : '90%', height: '90%',
            borderRadius: 4,
            flexDirection: 'column',
            maxWidth: 'initial',
            margin: 0,
            alignItems: 'center',
        }
    },
})
