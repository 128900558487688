import React, { Fragment } from 'react';
import { Avatar, Drawer as DrawerMUI, makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "./LinearProgress";
import { AppBar as AppBarMUI } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Api from "../../../Api";
import { AuthenticationLogout, UpdateAuthentication } from "../../../Action/AuthenticationAction";
import { DrawerMainLayoutClose, DrawerMainLayoutOpen } from "../../../Action/DrawerMainLayoutAction";
import CloseIcon from "@material-ui/icons/Close";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import UseAnimations from "react-useanimations";
import facebook from "react-useanimations/lib/facebook";
import twitter from "react-useanimations/lib/twitter";
import linkedin from "react-useanimations/lib/linkedin";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { AccountBalance, Description, LiveHelp, MeetingRoom, Menu, Settings } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { getRoutePathname } from "../../../Config/Route";
import ApartmentIcon from '@material-ui/icons/Apartment';
import { theme } from "../../../App";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ForumIcon from '@material-ui/icons/Forum';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { Alert } from '@material-ui/lab';

export default function AppBar(props) {
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const drawerMainLayoutReducer = useSelector(state => state.DrawerMainLayoutReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isInstitution, setIsInstitution] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isActiveTab, setIsActiveTab] = React.useState();
    const [isHolder, setIsHolder] = React.useState(false);

    const closeNav = (callback) => {
        callback();
        dispatch(DrawerMainLayoutClose())
    };

    const logout = () => {
        dispatch(AuthenticationLogout());
        Api.get({ route: 'authentication_logout' }, () => {
        });
    };

    const checkedHolder = () => {
        Api.get({
            route: 'learner_institution_list',
        }, (response) => {
            if (response.status === 200) {
                let data = response.data;
                dispatch(UpdateAuthentication({ name: 'isHolder', value: data.length > 0 }));
                setIsHolder(data.length > 0);
            }
            else {
                setIsHolder(false);
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
        if (screenReducer.screen === 'XS' || screenReducer.screen === 'SM') setIsOpen(false);
        dispatch(DrawerMainLayoutClose())
    }, [screenReducer.screen]);

    React.useEffect(() => {
        checkedHolder()
        switch (props.location.pathname) {
            case "/learner/home":
                setIsActiveTab(1);
                break;
            case "/learner/catalog":
                setIsActiveTab(2);
                break;
            case "/learner/library-resource":
                setIsActiveTab(3);
                break;
            case "/learner/community":
                setIsActiveTab(4);
                break;
            case "/learner/institution/list":
                setIsActiveTab(5);
                break;
        }
    }, [props.location.pathname]);

    React.useEffect(() => setIsInstitution(authenticationReducer.isInstituOrganism), []);

    return (
        <>
            <AppBarMUI position="fixed" className={`${classes.appBar}`} elevation={2}>
                <Toolbar>
                    <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.appBarLogo} />

                    {isSmallView ? <div style={{ flex: 1 }}>
                        <IconButton component="span" className={classes.appBarLogout} onClick={() => dispatch(DrawerMainLayoutOpen())}>
                            <Menu className={classes.appBarLogoutIcon} />
                        </IconButton>
                    </div>

                        :

                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end', margin: 5 }}>
                            <IconButton className={`${classes.appBarBtn} ${isActiveTab === 1 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_home'))}>
                                Tableau de bord
                            </IconButton>
                            {!isInstitution &&
                                <IconButton className={`${classes.appBarBtn} ${isActiveTab === 2 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_catalog'))}>
                                    Catalogue
                                </IconButton>
                            }
                            <IconButton className={`${classes.appBarBtn} ${isActiveTab === 3 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_library_resource'))}>
                                Bibliothèque de ressources
                            </IconButton>
                            <IconButton className={`${classes.appBarBtn} ${isActiveTab === 4 && classes.activeTab}`} onClick={() => props.history.push(getRoutePathname('learner_community'))}>
                                Forum
                            </IconButton>
                            <IconButton component="span" className={classes.appBarSubMenu} onClick={() => setIsOpen(true)}>
                                {(authenticationReducer.firstname && authenticationReducer.lastname) &&
                                    <Avatar className={classes.avatar}>{authenticationReducer.firstname.charAt().toUpperCase()}{authenticationReducer.lastname.charAt().toUpperCase()}
                                    </Avatar>
                                }
                                <ArrowDropDownIcon />
                            </IconButton>
                        </div>}
                </Toolbar>
                <LinearProgress />
            </AppBarMUI>

            <Fragment>
                <DrawerMUI className={classes.subMenu} anchor={'right'} open={isOpen} onClose={() => setIsOpen(false)}>
                    <div className={classes.drawerNav}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.appBarLogo2} />
                            <CloseIcon className={classes.drawerIconClose} onClick={() => setIsOpen(false)} />
                        </div>
                        <hr />
                    </div>
                    <List style={{ color: '#FFF', padding: 10 }}>
                        {isHolder &&
                            <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); props.history.push(getRoutePathname('learner_institution_list')) }}>
                                <ListItemIcon className={classes.drawerListIcon}><ApartmentIcon style={{ fontSize: 15 }} /></ListItemIcon>
                                <ListItemText className={classes.drawerListText} primary={'Mes établissements'} />
                            </ListItem>
                        }
                        <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); props.history.push(getRoutePathname('learner_my_info')) }}>
                            <ListItemIcon className={classes.drawerListIcon}><Settings style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes informations'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); props.history.push(getRoutePathname('learner_document')) }}>
                            <ListItemIcon className={classes.drawerListIcon}><Description style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes documents'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); props.history.push(getRoutePathname('learner_invoice')) }}>
                            <ListItemIcon className={classes.drawerListIcon}><AccountBalance style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes factures'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => { setIsOpen(false); window.open(theme.faq, "_blank") }}>
                            <ListItemIcon className={classes.drawerListIcon}><LiveHelp style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'FAQ'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => window.open('mailto:' + theme.tutor_mail))}>
                            <ListItemIcon className={classes.drawerListIcon}><EmojiPeopleIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Contact tuteur'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(logout)}>
                            <ListItemIcon className={classes.drawerListIcon}><MeetingRoom style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Déconnexion'} />
                        </ListItem>
                    </List>
                    <div className={classes.drawerNetworkSmall}>
                        <hr />

                        <UseAnimations animation={facebook} size={20} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left', color: '#5E6E82', }} onClick={() => window.open(theme.facebook, "_blank")} />
                        <UseAnimations animation={twitter} size={20} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left', color: '#5E6E82', }} onClick={() => window.open(theme.twitter, "_blank")} />
                        <UseAnimations animation={linkedin} size={20} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left', color: '#5E6E82', }} onClick={() => window.open(theme.linkedin, "_blank")} />
                    </div>
                </DrawerMUI>
            </Fragment>

            <Fragment>
                <DrawerMUI anchor={'top'} open={drawerMainLayoutReducer.isOpen} onClose={() => dispatch(DrawerMainLayoutClose())}>
                    <div className={classes.drawerNav}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.appBarLogo2} />
                            <CloseIcon className={classes.drawerIconClose} onClick={() => dispatch(DrawerMainLayoutClose())} />
                        </div>
                        <hr />
                    </div>
                    <List style={{ color: '#FFF', padding: 0 }}>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_home')))}>
                            <ListItemIcon className={classes.drawerListIcon}><DashboardIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Tableau de bord'} />
                        </ListItem>
                        {!isInstitution &&
                            <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_catalog')))}>
                                <ListItemIcon className={classes.drawerListIcon}><PhotoLibraryIcon style={{ fontSize: 15 }} /></ListItemIcon>
                                <ListItemText className={classes.drawerListText} primary={'Catalogue'} />
                            </ListItem>
                        }
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_library_resource')))}>
                            <ListItemIcon className={classes.drawerListIcon}><LibraryBooksIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Bibliothèque de ressources'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_community')))}>
                            <ListItemIcon className={classes.drawerListIcon}><ForumIcon style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Forum'} />
                        </ListItem>
                        {isHolder &&
                            <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_institution_list')))}>
                                <ListItemIcon className={classes.drawerListIcon}><ApartmentIcon style={{ fontSize: 15 }} /></ListItemIcon>
                                <ListItemText className={classes.drawerListText} primary={'Mes établissements'} />
                            </ListItem>
                        }
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_my_info')))}>
                            <ListItemIcon className={classes.drawerListIcon}><Settings style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes informations'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_document')))}>
                            <ListItemIcon className={classes.drawerListIcon}><Description style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes documents'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => props.history.push(getRoutePathname('learner_invoice')))}>
                            <ListItemIcon className={classes.drawerListIcon}><AccountBalance style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Mes factures'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(() => window.open(theme.faq, "_blank"))}>
                            <ListItemIcon className={classes.drawerListIcon}><LiveHelp style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'FAQ'} />
                        </ListItem>
                        <ListItem className={classes.drawerListItem} onClick={() => closeNav(logout)}>
                            <ListItemIcon className={classes.drawerListIcon}><MeetingRoom style={{ fontSize: 15 }} /></ListItemIcon>
                            <ListItemText className={classes.drawerListText} primary={'Déconnexion'} />
                        </ListItem>
                    </List>
                    <div className={classes.drawerNetworkSmall}>
                        <UseAnimations animation={facebook} size={20} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left', color: '#5E6E82', }} onClick={() => window.open(theme.facebook, "_blank")} />
                        <UseAnimations animation={twitter} size={20} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left', color: '#5E6E82', }} onClick={() => window.open(theme.twitter, "_blank")} />
                        <UseAnimations animation={linkedin} size={20} wrapperStyle={{ cursor: 'pointer', padding: 7, float: 'left', color: '#5E6E82', }} onClick={() => window.open(theme.linkedin, "_blank")} />
                    </div>
                </DrawerMUI>
            </Fragment>

            {/* <== Info isLoginAs */}
            {authenticationReducer.isLoginAs &&
                <Alert className={classes.isLoginAs} severity="error">
                    Vous êtes connecté en tant qu'admin tous les logs, décomptes et temps ne seront pas comptabilisés.
                </Alert>}
            {/* <== Info isLoginAs */}
        </>
    );
}

const useStyles = (theme) => makeStyles(style => ({
    appBar: {
        zIndex: 200,
        transition: style.transitions.create(['width', 'margin'], {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        background: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.5)',
        height: 80,
        '& .MuiToolbar-regular': {
            height: 80,
            minHeight: '80px !important',
            paddingLeft: 0,
            paddingRight: 0,
        },
        position: 'relative'
    },
    drawerListIcon: {
        minWidth: 'initial',
        color: '#5E6E82'
    },
    appBarSubMenu: {
        padding: 4,
        border: '1px solid #DADADA',
        borderRadius: 30,
    },
    subMenu: {
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            width: 285
        }
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 12,
        width: 30,
        height: 30,
        fontWeight: 'bold'
    },
    appBarLogo: {
        height: theme.id === 5 ? 40 : 25,
        paddingLeft: 25,
        float: 'right'
    },
    appBarLogo2: {
        height: theme.id === 5 ? 40 : 25,
        padding: 5,
    },
    appBarBtn: {
        padding: 12,
        margin: 0,
        color: '#5E6E82',
        fontWeight: 700,
        fontSize: 14,
        borderRadius: 0,
        position: 'relative',
        float: 'right'
    },
    appBarLogout: {
        float: 'right',
        padding: 10,
        margin: 8,
        marginRight: 12,
        color: '#5E6E82'
    },
    appBarLogoutIcon: {
        fontSize: 22
    },
    drawerIconClose: {
        right: 0,
        padding: 12,
        fontSize: 20,
        zIndex: 2,
        cursor: 'pointer',
        margin: '0 0 0 auto',
    },
    drawerNetworkSmall: {
        width: 'fit-content',
        display: 'block',
        margin: 'auto auto 17px auto',
        color: '#5E6E82',
    },
    drawerNav: {
        display: 'block',
        margin: '5px 10px'
    },
    drawerListItem: {
        paddingTop: 3,
        paddingBottom: 3,
        gap: 5,
        '&:hover': {
            background: 'rgb(236 236 236)'
        }
    },
    drawerListText: {
        cursor: 'pointer',
        margin: 0,
        padding: 5,
        '& .MuiListItemText-primary': {
            fontSize: 14,
            color: '#5E6E82'
        }
    },
    activeTab: {
        color: theme.colorPrimary,
    },
    isLoginAs: {
        position: 'absolute',
        zIndex: 400,
        top: 60,
        width: '100%',
        justifyContent: 'center',
        padding: 0
    }
}));
